import { IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import { ItemContent, itemContensAtom } from '../../../jotai';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import MyAppBar from '../../../MyAppBar';
import EditIcon from '@mui/icons-material/Edit';
import styles from './LogList.module.scss';
import { useNavigate } from 'react-router-dom';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { DataId } from '279map-core';
import { MainViewContext } from '../MainView';

type Props = {
}

export default function LogList(props: Props) {
    const [ items ] = useAtom(itemContensAtom);
    const { getMap, selectItem, setSelectItem } = useContext(MainViewContext);

    const handleItemClick = useCallback((itemId: DataId) => {
        setSelectItem(itemId);
        getMap()?.focusItem({
            itemId,
        });
    }, [getMap, setSelectItem])

    return (
        <>
            <MyAppBar title='ログ一覧' />
            <div className={styles.Contents}>
                <List className={styles.List}>
                    {items.map(item => {
                        return (
                            <MyListItem key={item.id} item={item}
                                selected={item.id===selectItem}
                                onClick={()=>handleItemClick(item.id)} />
                        )
                    })}
                </List>
            </div>
        </>
    );
}

type MyListItemProps = {
    item: ItemContent;
    selected?: boolean;
    onClick: () => void;
}
function MyListItem(props: MyListItemProps) {
    const date = useMemo(() => {
        if (!props.item.date) return '';
        return dayjs(props.item.date).format('YYYY-MM-DD HH:mm');

    }, [props.item])

    const navigate = useNavigate();
    const handleEditBtnClicked = useCallback(() => {
        navigate('/edit/' + props.item.id)
    }, [navigate, props.item.id]);

    return (
        <ListItem
            onClick={props.onClick}
            secondaryAction={
                <>
                    {props.item.temporary &&
                        <IconButton>
                            <ArrowCircleUpIcon />
                        </IconButton>
                    }
                    <IconButton onClick={handleEditBtnClicked}>
                        <EditIcon />
                    </IconButton>
                </>
            }
        >
            <ListItemButton selected={props.selected}>
                <ListItemText
                    primary={props.item.title}
                    secondary={date}               
                />
            </ListItemButton>
        </ListItem>
    )

}