import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { AppBar, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext } from './App';
import { useAuth0 } from '@auth0/auth0-react';
import ListIcon from '@mui/icons-material/List';
import { useAtomCallback } from 'jotai/utils';
import { contentsAtom, itemsAtom, targetMapAtom } from './jotai';
import { useNavigate } from 'react-router-dom';

type Props = {
    title: string;
    rightComponents?: ReactNode;
}

export default function MyAppBar(props: Props) {
    const { authConfig } = useContext(AuthContext);

    const enableLogin = useMemo(() => {
        return authConfig?.__typename === 'Auth0Config';
    }, [authConfig?.__typename])

    const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

    const handleLogout = useAtomCallback(
        useCallback((get, set) => {
            logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
                openUrl(url) {
                    // // 画面リロード
                    // const path = window.location.origin + (id ? `/${id}` : '');
                    // window.location.href = path;
                }
            });
            set(itemsAtom, []);
            set(contentsAtom, []);
            set(targetMapAtom, undefined);
        }, [logout])
    )

    const handleLogin = useCallback(() => {
        loginWithRedirect();
    }, [loginWithRedirect]);

    const [ showDrawer, setShowDrawer ] = useState(false);
    const navigate = useNavigate();

    const resetTargetMap = useAtomCallback(
        useCallback((get, set) => {
            set(targetMapAtom, undefined);
            navigate('/map-select')
        }, [navigate])
    )

    const rightButtons = useMemo(() => {
        if (props.rightComponents) return props.rightComponents;

        if (enableLogin) {
            if (isAuthenticated) {
                return (
                    <Button color="inherit" onClick={handleLogout}>Logout</Button>
                )
            } else {
                return (
                    <Button color="inherit" onClick={handleLogin}>Login</Button>
                )
            }
        }

        return null;
        
    }, [enableLogin, handleLogin, handleLogout, isAuthenticated, props.rightComponents])

    return (
        <>
            <AppBar position='sticky' component='nav'>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={()=>setShowDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {props.title}
                    </Typography>
                    {rightButtons}
                </Toolbar>                
            </AppBar>
            <Drawer open={showDrawer} onClose={()=>setShowDrawer(false)}>
                <List sx={{width: '250px'}}>
                    <ListItem disablePadding>
                        <ListItemButton onClick={resetTargetMap}>
                            <ListItemIcon>
                                <ListIcon />
                            </ListItemIcon>
                            <ListItemText primary='地図変更' />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
}
