import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import MyAppBar from '../../MyAppBar';
import { AuthContext, MAP_SERVER_HOST, MAP_SERVER_SSL } from '../../App';
import { Auth, DatasourceLocationKindType, ItemDatasourceInfo, OnMapLoadParam, TsunaguMap, getAccessableMapList } from '279map-core';
import { Button, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import styles from './MapListView.module.scss';
import { useAtomCallback } from 'jotai/utils';
import { targetMapAtom } from '../../jotai';
import { useNavigate } from 'react-router-dom';

type Props = {
}

type MapInfo = {
    mapId: string;
    name: string;
    description?: string;
    thumbnail?: string;
}

export default function MapListView(props: Props) {
    const [ mapList, setMapList ] = useState<MapInfo[]>([]);
    const { token } = useContext(AuthContext);
    const [ mapId, setMapId ] = useState<string|undefined>();
    const [ itemDatasources, setItemDatasources ] = useState<ItemDatasourceInfo[]>([]);
    const [ itemDatasourceId, setItemDatasourceId ] = useState<string|undefined>();

    useEffect(() => {
        getAccessableMapList(MAP_SERVER_HOST, MAP_SERVER_SSL, token)
        .then(result => {
            if (result) {
                setMapList(result.filter(res => res.authLv === Auth.Admin || res.authLv === Auth.Edit));
            }
        })
    }, [token]);

    const mapServer = useMemo(() => {
        return {
            host: MAP_SERVER_HOST,
            ssl: MAP_SERVER_SSL,
            token: token,
        };
    }, [token]);

    const onMapLoad = useCallback(async(param: OnMapLoadParam) => {
        setItemDatasources(param.itemDatasources.filter(ds => ds.config.kind === DatasourceLocationKindType.RealItem));
    }, []);

    const navigate = useNavigate();
    const handleOk = useAtomCallback(
        useCallback((get, set) => {
            if (!mapId || !itemDatasourceId) return;
            set(targetMapAtom, {
                mapId,
                itemDatasourceId,
            })
            navigate('/');
        }, [itemDatasourceId, mapId, navigate])
    )

    return (
        <>
            <MyAppBar title='地図選択' />
            <div className={styles.Container}>
                <span>1. 地図選択</span>
                <List>
                {mapList.map(map => {
                    return (
                        <ListItem key={map.mapId}>
                            <ListItemButton
                                selected={mapId === map.mapId}
                                onClick={()=>setMapId(map.mapId)}>
                                <ListItemText primary={map.name} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
                </List>

                <span>2. データソース選択</span>
                <List>
                    {itemDatasources.map(ds => {
                        const name = ds.groupName ? `${ds.groupName} - ${ds.name}` : ds.name;
                        return (
                            <ListItem key={ds.datasourceId}>
                                <ListItemButton
                                    selected={itemDatasourceId === ds.datasourceId}
                                    onClick={()=>setItemDatasourceId(ds.datasourceId)}
                                >
                                    <ListItemText primary={name} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </List>

                <Button
                    variant='contained'
                    disabled={!mapId || !itemDatasourceId}
                    onClick={handleOk}>
                    OK
                </Button>

                {mapId &&
                    <TsunaguMap
                        mapServer={mapServer}
                        mapId={mapId}
                        onMapLoad={onMapLoad}
                    />
                }

            </div>
        </>
    );
}