import { ContentFieldDefine, DataId } from '279map-core';
import { TextField } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import useContent from './useContent';
import styles from './ContentFieldsForm.module.scss';
import dayjs from 'dayjs';

export type ValueType = {[key: string]: any};
type Props = {
    values: ValueType;  // 編集中のID
    onChange: (val: ValueType) => void;
    target: {
        type: 'new';
    } | {
        type: 'edit';
        contentId: DataId;
    }
}

/**
 * コンテンツの各項目の編集フォーム
 */
export default function ContentFieldsForm(props: Props) {

    const { fieldsDefine: originalFieldsDefine } = useContent();

    const fieldsDefine = useMemo(() => {
        // titleを先頭に持ってくる
        return originalFieldsDefine.sort((a, b) => {
            if (a.type === 'title') return -1;
            if (b.type === 'title') return 1;
            return 0;
        })
    }, [originalFieldsDefine]);

    const handleChange = useCallback((key: string, val: any) => {
        const newVal = Object.assign({}, props.values, { 
            [key]: val,
        });
        props.onChange(newVal);
    }, [props])

    return (
        <div className={styles.Form}>
        {
            fieldsDefine.map(field => {
                return (
                    <Field key={field.key} def={field}
                        value={props.values[field.key]}
                        contentId={props.target.type === 'edit' ? props.target.contentId : undefined}
                        onChange={(val) => handleChange(field.key, val)} />
                )
            })
        }
        </div>
    );
}

type FieldProps = {
    def: ContentFieldDefine;
    value: any;
    contentId?: DataId; // 編集時、指定
    onChange: (val: any) => void;
}
function Field(props: FieldProps) {
    // const { getMap } = useContext(MapContext);
    // const [ defaultImage, setDefaultImage ] = useState<string|undefined>();

    // useEffect(() => {
    //     if (props.def.type === 'image' && props.defaultValue) {
    //         const values = props.defaultValue as number[];
    //         // ひとまず画像は１つを想定
    //         if (values.length === 0) return;
    //         getMap()?.loadImage({
    //             imageId: values[0],
    //             size: ThumbSize.Thumbnail,
    //             refresh: true,
    //         }).then(thumb => {
    //             setDefaultImage(thumb);
    //         })
    //     }
    // }, [getMap, props.contentId, props.def.key, props.def.type, props.defaultValue])

    const name = useMemo(() => {
        switch(props.def.type) {
            case 'title':
                return 'タイトル';
            case 'url':
                return 'URL';
            default:
                return props.def.label;
        }
    }, [props.def])

    // const handleChangeImage = useCallback((image: ImageType) => {
    //     switch(image.type) {
    //         case 'delete':
    //             props.onChange(null);
    //             break;
    //         case 'url':
    //             props.onChange(image.value);
    //             break;
    //         case 'reset':
    //             props.onChange(undefined);
    //             break;
    //     }
    // }, [props]);

    const handleChangeDate = useCallback((val: string) => {
        const newVal = dayjs(val).format('YYYY-MM-DDTHH:mmZ');
        props.onChange(newVal);
    }, [props]);

    if ('readonly' in props.def && props.def.readonly) {
        return null;
    }

    switch(props.def.type) {
        case 'title':
        case 'string':
            return (
                <TextField label={name} variant='standard' type='text'
                    value={props.value ?? ''} onChange={(evt) => props.onChange(evt.target.value)} />
            )
        case 'url':
            return (
                <TextField label={name} variant='standard' type='url'
                    value={props.value ?? ''} onChange={(evt) => props.onChange(evt.target.value)} />
            )
        case 'number':
            return (
                <TextField label={name} type='number'
                    value={props.value ?? 0} onChange={(evt) => props.onChange(Number(evt.target.value))} />
            )
        case 'text':
            return (
                <TextField label={name} multiline rows={3}
                    value={props.value ?? ''} onChange={(evt) => props.onChange(evt.target.value)} />
            )
        case 'date':
            return (
                <TextField label={name} type='datetime-local' 
                    value={dayjs(props.value).format('YYYY-MM-DDTHH:mm')} onChange={(evt) => handleChangeDate(evt.target.value)} />
            )
        // case 'category':
        //     return (
        //         <label className={`MuiInputLabel-root ${styles.CategoryLabel}`}>
        //             <span>{props.def.label}</span>
        //             <CategorySelector selected={props.value ?? []} onChange={props.onChange} />
        //         </label>
        //     )
        // case 'image':
        //     return (
        //         <label>
        //             <span>画像</span>
        //             <ImageForm defaultImage={defaultImage} onUpdate={handleChangeImage} />
        //         </label>
        //     )
        default:
            return null;
    }

}