import { atomWithStorage } from 'jotai/utils';
import { ContentFieldDefine, ContentValueMap, ContentsDefine, DataId, ItemType } from '279map-core';
import { atom } from 'jotai';
import { ValueType } from '../views/main/log-editor/ContentFieldsForm';
import { Location } from '../views/main/types';

/**
 * 対象の地図
 */
type MapDefine = {
    mapId: string;
    itemDatasourceId: string;
}
export const targetMapAtom = atomWithStorage<MapDefine|undefined>('targetMap', undefined);

export const contentFieldsDefineAtom = atom<ContentFieldDefine[]>([]);

/**
 * アイテム一覧
 */
export const itemsAtom = atom<ItemType[]>([]);

/**
 * コンテンツ一覧
 */
export const contentsAtom = atom<ContentsDefine[]>([]);

/**
 * 一時保存データ
 */
type TemporaryData = {
    location: Location | undefined;
    values: ValueType;
} & (
    {
        type: 'new';
        tempId: DataId;
    } | {
        type: 'update';
        id: DataId;
    }
)
export const temporaryDataAtom = atomWithStorage<TemporaryData[]>('temporaryDataList', []);

/**
 * 一覧に表示するデータ一覧
 */
export type ItemContent = {
    id: DataId;
    title: string;
    location?: Location;
    date?: string;
    values: ContentValueMap;
    temporary?: boolean;
}
export const itemContensAtom = atom<ItemContent[]>((get) => {
    const items = get(itemsAtom);
    const contents = get(contentsAtom);
    const fieldDefine = get(contentFieldsDefineAtom);

    const titleField = fieldDefine.find(field => field.type === 'title');
    const dateField = fieldDefine.find(field => field.type === 'date');

    // 一保存データをマージする
    const temporaryDataList = get(temporaryDataAtom);
    const newTemporaryDataList = temporaryDataList.filter(tdl => tdl.type === 'new');
    const updateTemporaryDataList = temporaryDataList.filter(tdl => tdl.type === 'update');

    return items.map((item): ItemContent => {
        const content = contents.find(c => c.id === item.id);
        const values = content?.values ?? {};
        const date = dateField ? (values[dateField.key] as string) : undefined;
        const geometry = item.geoInfo.geometry;
        const coordinates = geometry.type === 'Point' ? geometry.coordinates : undefined

        const tdl = updateTemporaryDataList.find(utdl => {
            const utdlId = utdl.type === 'new' ? utdl.tempId : utdl.id;
            return utdlId === item.id;
        });
        if (tdl) {
            return {
                id: tdl.type === 'new' ? tdl.tempId : tdl.id,
                date: dateField ? (tdl.values[dateField.key] as string) : undefined,
                title: titleField ? (tdl.values[titleField.key] as string) : '',
                location: tdl.location,
                values: tdl.values,
                temporary: true,
            }
        } else {
            return {
                id: item.id,
                title: titleField ? (values[titleField.key] as string) : '',
                location: coordinates ? {
                    longitude: coordinates[0],
                    latitude: coordinates[1]
                } : undefined,
                date,
                values,
            }
        }
    })
    .concat(
        newTemporaryDataList.map(tdl => ({
            id: tdl.type === 'new' ? tdl.tempId : tdl.id,
            date: dateField ? (tdl.values[dateField.key] as string) : undefined,
            title: titleField ? (tdl.values[titleField.key] as string) : '',
            location: tdl.location,
            values: tdl.values,
            temporary: true,
        }))
    )
    // 日時降順でソート
    .sort((a, b) => {
        // 日付なしは冒頭に表示されるようにする
        if (!a.date && !b.date) return 0;
        if (!a.date) return -1;
        if (!b.date) return 1;
        return -1 * a.date.localeCompare(b.date)
    })
})