import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MapListView from './views/map-list/MapListView';
import { Box, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import { ServerConfig, getAuthConfig } from '279map-core';
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import MainView from './views/main/MainView';
// @ts-ignore
import colors from './styles/_global.scss';

export const MAP_SERVER_HOST = process.env.REACT_APP_MAP_SERVER || window.location.host;
export const MAP_SERVER_SSL = (process.env.REACT_APP_MAP_SSL ? parseInt(process.env.REACT_APP_MAP_SSL) : window.location.protocol.startsWith('https:')) as boolean;

const theme = createTheme({
    palette: {
        primary: {
            main: colors.primaryColor,
        },
        secondary: {
            main: colors.secondaryColor, //'rgb(145, 120, 82)',
        },
        text: {
            primary: colors.textColor,
        }
    },
    // components: {
    //     MuiButton: {
    //         styleOverrides: {
    //             contained: {
    //                 boxShadow: 'unset',
    //             },
    //         }
    //     }
    // }
});


function App() {
    const [ authConfig, setAuthConfig ] = useState<ServerConfig>();


    /**
     * 起動時にAuth0情報を取得する
     */
    useEffect(() => {
        getAuthConfig(MAP_SERVER_HOST, MAP_SERVER_SSL)
        .then((result) => {
            setAuthConfig(result);
        })
    }, []);


    if (!authConfig) {
        return (
            <ThemeProvider theme={theme}>
                <Box display='flex' alignItems='center' justifyContent='center' width='100vw' height='100vh'>
                    <CircularProgress color='secondary' />
                </Box>
            </ThemeProvider>
        )
    }

    if (authConfig.__typename === 'Auth0Config') {
        return (
            <Auth0Provider
                domain={authConfig.domain}
                clientId={authConfig.clientId}
                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: authConfig.audience,
                }}
            >
                <AuthContextProvider authConfig={authConfig} />
            </Auth0Provider>
        );        
    } else {
        return <AuthContextProvider authConfig={authConfig} />
        ;
    }

}
export default App;


type AuthContextValue = {
    authConfig: ServerConfig | undefined;
    token: string | undefined;
}
export const AuthContext = React.createContext<AuthContextValue>({
    authConfig: undefined,
    token: undefined,
});

type AuthContextProviderProp = {
    authConfig: ServerConfig;
}
function AuthContextProvider(props: AuthContextProviderProp) {
    const [ token, setToken ] = useState<string|undefined>();
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    if (isAuthenticated && !token) {
        getAccessTokenSilently()
        .then(token => {
            setToken(token);
        });
    } else if (!isAuthenticated && token) {
        setToken(undefined);
    }

    const authContextValue = useMemo((): AuthContextValue => {
        return {
            authConfig: props.authConfig,
            token,
        }
    }, [props.authConfig, token]);

    return (
        <AuthContext.Provider value={authContextValue}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/map-select" element={<MapListView />} />
                        <Route path='/*' element={<MainView />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </AuthContext.Provider>
    )

}