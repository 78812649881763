import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useAtom } from 'jotai';
import { contentFieldsDefineAtom, contentsAtom, itemsAtom, targetMapAtom, temporaryDataAtom } from '../../jotai';
import { ContentsDefine, DataId, FeatureType, ItemType, MapKind, OnMapLoadParam, OnMapLoadResult, OverrideItem, TsunaguMap, TsunaguMapHandler, TsunaguMapProps } from '279map-core';
import { AuthContext, MAP_SERVER_HOST, MAP_SERVER_SSL } from '../../App';
import styles from './MainView.module.scss';
import { useAtomCallback } from 'jotai/utils';
import { Box, IconButton, Link } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LogList from './log-list/LogList';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LogEditorPage from './log-editor/LogEditorPage';
import MyAppBar from '../../MyAppBar';
import { Location } from './types';

type ItemLocation = {
    id?: DataId;
    location: Location;
}
export const MainViewContext = React.createContext({
    getMap: () => null as TsunaguMapHandler | null,
    setCurrentItemLocation: (val: ItemLocation | undefined) => {},
    selectItem: null as DataId | null,
    setSelectItem: (target: DataId | null) => {},
})

type Props = {
}

const iconDefine: TsunaguMapProps['iconDefine'] = {
    defines: [
        {
            id: 'pin',
            caption: '',
            imagePath: './pin.svg',
            useMaps: [MapKind.Real],
            defaultColor: '#271AA8',
        },
    ],
    defaultIconId: {
        real: 'pin',
    }
};
export const TemporaryId: DataId = 0;
export default function MainView(props: Props) {
    const [ targetMap ] = useAtom(targetMapAtom);
    const mapRef = useRef<TsunaguMapHandler>(null);
    const { token } = useContext(AuthContext);
    const [ mapLoaded, setMapLoaded ] = useState(false);
    // 編集ページで表示中のアイテム位置情報
    const [ currentItemLocation, setCurrentItemLocation ] = useState<ItemLocation|undefined>();
    const [ selectItem, setSelectItem ] = useState<DataId|null>(null);

    const mapServer = useMemo(() => {
        return {
            host: MAP_SERVER_HOST,
            ssl: MAP_SERVER_SSL,
            token: token,
        };
    }, [token]);

    const [ temporaryDataList ] = useAtom(temporaryDataAtom);

    const overrideItems = useMemo((): OverrideItem[] => {
        // 一時保存データの位置情報
        const items = temporaryDataList.filter(tdl=>tdl.location).map((tdl): OverrideItem => {
            if (tdl.type === 'new') {
                return {
                    type: 'new',
                    tempId: tdl.tempId,
                    datasourceId: targetMap?.itemDatasourceId ?? '',
                    geometry: {
                        type: 'Point',
                        coordinates: [tdl.location?.longitude ?? 0, tdl?.location?.latitude ?? 0],
                    },
                    geoProperties: {
                        featureType: FeatureType.STRUCTURE,
                    },
                    name: '',
                }
            } else {
                return {
                    type: 'update',
                    id: tdl.id,
                    geometry: {
                        type: 'Point',
                        coordinates: [tdl.location?.longitude ?? 0, tdl?.location?.latitude ?? 0],
                    },
                    geoProperties: {
                        featureType: FeatureType.STRUCTURE,
                    },
                }
            }
        })

        if (!currentItemLocation) return items;
        if (currentItemLocation.id) {
            return items.concat(
                {
                    type: 'update',
                    id: currentItemLocation.id,
                    geometry: {
                        type: 'Point',
                        coordinates: [currentItemLocation.location.longitude, currentItemLocation.location.latitude],
                    },
                    geoProperties: {
                        featureType: FeatureType.STRUCTURE,
                    }
                }
            );
        } else {
            return items.concat(
                {
                    type: 'new',
                    tempId: TemporaryId,
                    datasourceId: targetMap?.itemDatasourceId ?? '',
                    geometry: {
                        type: 'Point',
                        coordinates: [currentItemLocation.location.longitude, currentItemLocation.location.latitude],
                    },
                    geoProperties: {
                        featureType: FeatureType.STRUCTURE,
                    },
                    name: '',
                }
            );
        }
    }, [currentItemLocation, targetMap?.itemDatasourceId, temporaryDataList])

    const handleMapLoad = useAtomCallback(
        useCallback(async(get, set, param: OnMapLoadParam): Promise<OnMapLoadResult|void> => {
            if (!targetMap) {
                console.warn('map not defined');
                return;
            }
            const targetDatasource = param.contentDatasources.find(ids => ids.datasourceId === targetMap.itemDatasourceId);
            if (!targetDatasource) {
                console.warn('target datasource not find')
                return;
            }
            set(contentFieldsDefineAtom, targetDatasource.config.fields);
            // TsunaguMapへのrefが効かない問題の応急処置として、再レンダリングさせるためのフラグを用意
            setMapLoaded(true);

            // 指定のアイテムデータのみ表示状態にする
            return {
                initialItemLayerVisibles: param.itemDatasources.map(ds => (
                    {
                        datasourceId: ds.datasourceId,
                        visible: ds.datasourceId === targetMap.itemDatasourceId,
                    }
                ))
            }

        }, [targetMap])
    )

    const handleLoadedItemChanged = useAtomCallback(
        useCallback(async(get, set, items: ItemType[]) => {
            set(itemsAtom, items);

            if (!mapRef.current) return;

            const contents = await Promise.all(items.map(async(item) => {
                const result = await mapRef.current?.loadContent(item.id, (contentId, operation) => {
                    // 変更検知してatom update
                    if (operation === 'update') {
                        mapRef.current?.loadContent(contentId).then(res => {
                            if (res) {
                                set(contentsAtom, currentContents => {
                                    return currentContents.map(c => {
                                        if (c.id === contentId) { 
                                            return res.content;
                                        } else {
                                            return c;
                                        }
                                    })
                                })
                            }
                        })
                    } else {
                        // deleteの場合
                        set(contentsAtom, currentContents => {
                            return currentContents.filter(c => {
                                return c.id !== contentId;
                            })
                        })
                    }
                })
                return result?.content;
            }));
            set(contentsAtom, contents.filter(c => c) as ContentsDefine[]);

        }, [])
    )

    const { pathname } = useLocation();

    const navigate = useNavigate();
    const handlleNewBtnClicked = useCallback(() => {
        navigate('/new');
    }, [navigate])

    if (!targetMap) {
        return (
            <>
                <MyAppBar title='Log Register'
                />
                <Box className={styles.Contents} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                    <p>
                        地図が選択されていません
                    </p>
                    <p>
                        <Link href='/map-select'>地図選択画面</Link>
                    </p>
                </Box>
            </>
        )
    }

    return (
            <div className={styles.Container}>
                <div className={styles.Contents}>
                    <MainViewContext.Provider value={{
                        getMap: () => { 
                            console.log('getMap', mapRef.current?.getInstanceId())
                            return mapRef.current
                        },
                        setCurrentItemLocation,
                        selectItem,
                        setSelectItem
                    }}>
                        <Routes>
                            <Route path="/" element={<LogList />} />
                            <Route path="/new" element={<LogEditorPage />} />
                            <Route path="/edit/:id" element={<LogEditorPage />} />
                        </Routes>
                    </MainViewContext.Provider>
                </div>

                <div className={styles.Map}>
                    <TsunaguMap ref={mapRef}
                        mapServer={mapServer}
                        iconDefine={iconDefine}
                        popupMode='hidden'
                        mapId={targetMap.mapId}
                        overrideItems={overrideItems}
                        onMapLoad={handleMapLoad}
                        onShowingItemsChanged={handleLoadedItemChanged}
                        onSelectChange={setSelectItem}
                    />
                </div>

                {pathname === '/' &&
                    <div className={styles.NewBtnArea}>
                        <IconButton onClick={handlleNewBtnClicked}
                            size='large' color='primary'>
                            <AddIcon />
                        </IconButton>
                    </div>
                    }
            </div>
    );
}

