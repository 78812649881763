import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MyAppBar from '../../../MyAppBar';
import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ContentFieldsForm, { ValueType } from './ContentFieldsForm';
import styles from './LogEditorPage.module.scss';
import LocationField from './LocationField';
import { MainViewContext, TemporaryId } from '../MainView';
import { FeatureType } from '279map-core';
import { Location } from '../types';
import { useAtom } from 'jotai';
import { itemContensAtom, targetMapAtom, temporaryDataAtom } from '../../../jotai';
import useContent from './useContent';
import dayjs from 'dayjs';
import Fade from '@mui/material/Fade';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAtomCallback } from 'jotai/utils';

type Props = {
    // mode: 'new' | 'edit';
}

export default function LogEditorPage(props: Props) {
    const navigate = useNavigate();
    const { id: idStr } = useParams<{id: string}>();
    const id = useMemo(() => {
        if (!idStr) return 0;
        return parseInt(idStr);
    }, [idStr]);
    const [ items ] = useAtom(itemContensAtom);
    const currentItem = useMemo(() => {
        return items.find(item => item.id === id)
    }, [id, items]);
    const currentLocationRef = useRef<Location | undefined>(currentItem?.location);

    const { fieldsDefine } = useContent();
    const currentValues = useMemo(() => {
        const currentValues = currentItem?.values ?? {};
        fieldsDefine.forEach(fieldDef => {
            const val = currentValues[fieldDef.key];
            if (fieldDef.type === 'date' && !val) {
                // デフォルト値に現在日時を設定
                currentValues[fieldDef.key] = dayjs().format('YYYY-MM-DDTHH:mmZ');

            } else if (fieldDef.type === 'image') {
                // 現在、ODBA側の問題で配列文字列を渡すとエラーになるので、ひとまずundefinedを設定
                delete currentValues[fieldDef.key];
            }
        })
        return currentValues;

    }, [currentItem?.values, fieldsDefine])

    const [ values, setValues ] = useState<ValueType>(currentValues);
    const { getMap, setCurrentItemLocation } = useContext(MainViewContext);
    const [ location, setLocation ] = useState<Location|undefined>(currentLocationRef.current);
    const [ targetMap ] = useAtom(targetMapAtom);
    const [ loading, setLoading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState<string|null>();

    const isChange = useMemo(() => {
        if (JSON.stringify(currentValues) !== JSON.stringify(values)) return true;
        if (JSON.stringify(currentLocationRef.current ?? {}) !== JSON.stringify(location ?? {})) return true;
        return false;
    }, [ currentValues, location, values])

    /**
     * 更新可能か
     */
    const updatable = useMemo(() => {
        if (currentItem?.temporary) return true;
        return isChange;
    }, [currentItem, isChange])

    const mapPinId = useMemo(() => {
        return id ?? TemporaryId;
    }, [id]);

    const removeTempItem = useCallback(() => {
        setCurrentItemLocation(undefined);
    }, [setCurrentItemLocation])
    
    const handleCancel = useCallback(() => {
        removeTempItem();
        navigate('/');
    }, [navigate, removeTempItem])

    const [ temporaryData ] = useAtom(temporaryDataAtom);
    const mode = useMemo((): 'new' | 'update' => {
        if (!id) return 'new';
        
        const isNewData = temporaryData.find(td => td.type === 'new' && td.tempId === id);
        return isNewData ? 'new' : 'update';
    }, [id, temporaryData])

    const handleSave = useAtomCallback(
        useCallback(async(get, set) => {
            if (!targetMap) {
                setErrorMessage('連携対象の地図が設定されていません。リロードしてやり直してください。');
                return;
            }

            const map = getMap();
            if (!map) {
                setErrorMessage('想定外のエラーです。リロードしてやり直してください。');
                return;
            }

            if (!location) {
                setErrorMessage('位置が指定されていません。');
                return;
            }

            setLoading(true);

            try {
                removeTempItem();

                if (mode === 'new') {
                    await map.registData({
                        datasourceId: targetMap.itemDatasourceId,
                        item: {
                            geo: {
                                geometry: {
                                    type: 'Point',
                                    coordinates: [location.longitude, location.latitude],
                                },
                                geoProperties: {
                                    featureType: FeatureType.STRUCTURE,
                                }
                            }
                        },
                        contents: {
                            values,
                        }
                    });
            
                } else {
                    const itemId = {
                        id: id ?? '',
                        dataSourceId: targetMap.itemDatasourceId ?? '',
                    }
                    await map.updateData({
                        key: {
                            type: 'dataId',
                            dataId: itemId.id,
                        },
                        item: {
                            geo: {
                                geometry: {
                                    type: 'Point',
                                    coordinates: [location.longitude, location.latitude],
                                },
                                geoProperties: {
                                    featureType: FeatureType.STRUCTURE,
                                }
                            }
                        },
                        contents: {
                            values,
                        }
                    })
                }
            
                // 一時保存データを削除する
                set(temporaryDataAtom, currentDataList => {
                    return currentDataList.filter(data => {
                        const dataId = data.type === 'new' ? data.tempId : data.id;
                        return dataId !== id;
                    })
                })
                navigate('/');

            } catch(err) {
                setErrorMessage('登録に失敗しました.' + err);

            } finally {
                setLoading(false);

            }
        }, [targetMap, getMap, location, removeTempItem, values, navigate, mode, id])
    )

    const handleChangeLocation = useCallback((loc: Location) => {
        if (!targetMap) return;
        const map = getMap();
        if (!map) {
            console.warn('not find map');
            return;
        }

        setLocation(loc);
        setCurrentItemLocation({
            id,
            location: loc,
        })

        // 更新時に、更新後の位置にフォーカスするために、少し間を置く
        setTimeout(() => {
            map.focusItem({
                itemId: mapPinId,
                zoom: true,
                select: true,
            })
        }, 100)
    }, [getMap, id, mapPinId, setCurrentItemLocation, targetMap])

    const remove = useCallback(async() => {
        if (mode !== 'update') return;
        if (!targetMap) {
            setErrorMessage('連携対象の地図が設定されていません。リロードしてやり直してください。');
            return;
        }

        const map = getMap();
        if (!map) {
            setErrorMessage('想定外のエラーです。リロードしてやり直してください。');
            return;
        }
        
        setLoading(true);

        try {
            removeTempItem();

            await map.removeData(id);

            navigate('/');

        } catch(err) {
            setErrorMessage('削除に失敗しました.' + err);

        } finally {
            setLoading(false);

        }

    }, [getMap, id, mode, navigate, removeTempItem, targetMap])

    useEffect(() => {
        // 編集画面表示時に、現在の位置にフォーカスする
        if (!targetMap) return;
        if (!id) return;

        try {
            getMap()?.focusItem({
                itemId: id,
                zoom: true,
                select: true,
            })
    
        } catch(err) {
            // 現在、core側の問題で、直接このページを開くとfocusItemに失敗するので
        }

    }, [getMap, id, targetMap])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleModeBtnClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);
    
    const handleMoreMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleDeleteMenuClick = useCallback(() => {
        setAnchorEl(null);
        setShowConfirm(true)
    }, []);

    const [showConfirm, setShowConfirm] = useState(false);

    const handleConfirmCancel = useCallback(() => {
      setShowConfirm(false);
    }, []);

    const handleTemporarySave = useAtomCallback(
        useCallback((get, set) => {
            // 一時保存データ登録
            set(temporaryDataAtom, cur => {
                if (mode === 'new') {
                    // 新規追加
                    return cur.concat({
                        type: 'new',
                        tempId: dayjs().valueOf(),
                        location,
                        values,
                    })
                } else {
                    // 更新
                    // 既に一時保存データが存在するか
                    const exist = cur.some(item => {
                        const itemId = item.type === 'new' ? item.tempId : item.id;
                        return itemId === id;
                    });

                    if (exist) {
                        // 既に一時保存データが存在する場合は、上書き
                        return cur.map(item => {
                            const isMe = function() {
                                if (item.type === 'new') {
                                    return item.tempId === id;
                                } else {
                                    return item.id === id;
                                }
                            }();
                            if (isMe) {
                                const newItem = structuredClone(item);
                                newItem.location = location;
                                newItem.values = values;
                                return newItem;
                            } else {
                                return item;
                            }
                        })
    
                    } else {
                        // 一時保存データが存在しない場合は、新規追加
                        return cur.concat({
                            type: 'update',
                            id,
                            location,
                            values,
                        })
    
                    }
                }
            })

            removeTempItem();
            navigate('/');
        }, [removeTempItem, navigate, mode, location, values, id])
    );

    const handleDeleteTemporaryData = useAtomCallback(
        useCallback((get, set) => {
            set(temporaryDataAtom, current => {
                return current.filter(item => {
                    const itemId = item.type === 'new' ? item.tempId : item.id;
                    return itemId !== id;
                })
            })
            navigate('/');
        }, [id, navigate])
    )

    return (
        <>
            <MyAppBar title={!id ? '新規登録' : '編集'}
                rightComponents={
                    <>
                        <Button color="inherit" disabled={loading || !updatable} onClick={handleSave}>
                            {mode === 'new' ? '登録' : '更新'}
                        </Button>
                        <IconButton color="inherit" disabled={loading} onClick={handleModeBtnClick}>
                            <MoreIcon />                                
                        </IconButton>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={!!anchorEl}
                            onClose={handleMoreMenuClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem disabled={!isChange} onClick={handleTemporarySave}>一時保存</MenuItem>
                            {currentItem?.temporary &&
                                <MenuItem onClick={handleDeleteTemporaryData}>一時保存データ破棄</MenuItem>
                            }
                            {mode === 'update' &&
                                <MenuItem onClick={handleDeleteMenuClick}>削除</MenuItem>
                            }
                            <MenuItem onClick={handleCancel}>キャンセル</MenuItem>
                        </Menu>
                    </>
                }
            />
            <div className={styles.Contents}>
                {errorMessage &&
                    <Alert severity="error">{errorMessage}</Alert>
                }
                <LocationField value={location} onChange={handleChangeLocation} />
                <ContentFieldsForm
                            target={{type: 'new'}}
                            values={values} onChange={setValues} />
                {loading &&
                    <div className={styles.ProgressArea}>
                        <CircularProgress color="secondary" />
                    </div>
                }
            </div>

            <Dialog
                open={showConfirm}
                onClose={handleConfirmCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    確認
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        削除してよろしいですか.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmCancel}>キャンセル</Button>
                    <Button onClick={remove} autoFocus>
                        削除
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}