import React, { useCallback, useContext, useState } from 'react';
import styles from './LocationField.module.scss';
import { CircularProgress, IconButton, Snackbar, TextField } from '@mui/material';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import { MainViewContext } from '../MainView';
import PlaceIcon from '@mui/icons-material/Place';
import { FeatureType } from '279map-core';
import { Location } from '../types';
import { useAtom } from 'jotai';
import { targetMapAtom } from '../../../jotai';

type Props = {
    value: Location | undefined;
    onChange: (location: Location) => void;
}

export default function LocationField(props: Props) {
    const [ targetMap ] = useAtom(targetMapAtom);
    const [ gettingLocation, setGettingLocation ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState<string|null>(null);
    const { getMap } = useContext(MainViewContext);

    const handleGetLocation = useCallback(async() => {
        const map = getMap();
        if (!map) {
            console.warn('not map');
            return;
        }
        setErrorMessage(null);
        setGettingLocation(true);

        const getCurrentPosition = function() {
            return new Promise<Location>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        })
                    },
                    function(error) {
                        reject('現在地の取得に失敗しました。エラーコード: ' + error.code);
                    }
                  );
            })
        }
        try {
            const location = await getCurrentPosition();
            setGettingLocation(false);
            props.onChange(location);
            // map.focusItem({
            //     itemId: tempItem.id,
            //     zoom: true,
            //     select: true,
            // })

        } catch(err) {
            setGettingLocation(false);
            console.warn(err);
        }


    }, [getMap, props])

    const handlePickLocation = useCallback(async() => {
        if (!targetMap) {
            console.warn('not target map');
            return;
        }
        const map = getMap();
        if (!map) {
            console.warn('not find map');
            return;
        }
        setErrorMessage(null);
        setGettingLocation(true);
        const tempItem = await map.drawTemporaryFeature({
            featureType: FeatureType.STRUCTURE,
            datasourceId: targetMap.itemDatasourceId,
        });
        setGettingLocation(false);
        if (!tempItem) return;

        const coordinates = tempItem.geometry.type === 'Point' ? tempItem.geometry.coordinates : undefined;
        if (!coordinates) {
            console.warn('not point')
            return;
        }
        const newLocation = {
            longitude: coordinates[0],
            latitude: coordinates[1],
        }
        props.onChange(newLocation);
        // map.focusItem({
        //     itemId: tempItem.id,
        //     zoom: true,
        //     select: true,
        // })

    }, [getMap, targetMap, props])

    return (
        <div className={styles.Row}>
            <TextField label='緯度' variant='standard' type='number' InputProps={{readOnly: true}}
                value={props.value?.latitude ?? ''}
            />
            <TextField label='経度' variant='standard' type='number' InputProps={{readOnly: true}}
                value={props.value?.longitude ?? ''}
            />
            <div className={styles.BtnArea}>
                <IconButton disabled={gettingLocation} onClick={handleGetLocation}>
                    <MyLocationIcon />
                </IconButton>
                <IconButton disabled={gettingLocation} onClick={handlePickLocation}>
                    <PlaceIcon />
                </IconButton>
                {gettingLocation &&
                    <div className={styles.Progress}>
                        <CircularProgress color='secondary' />
                    </div>
                }
            </div>

            <Snackbar
                open={!!errorMessage}
                autoHideDuration={6000}
                onClose={()=>setErrorMessage(null)}
                message={errorMessage}
                // action={action}
                />
        </div>
    );
}