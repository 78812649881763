import { useCallback } from "react";
import { ContentFieldDefine, ContentValueMap } from "279map-core";
import { useAtom } from "jotai";
import { contentFieldsDefineAtom } from "../../../jotai";

/**
 * コンテンツ関連のカスタムフック
 * @param props 
 * @returns 
 */
export default function useContent() {
    const [ fieldsDefine ] = useAtom(contentFieldsDefineAtom);

    /**
     * 指定のtypeの値を返す。
     * 複数該当するものがある場合は、最初にヒットしたものについて返す
     */
    const getValue = useCallback((values: ContentValueMap, type: ContentFieldDefine['type']) => {
        const def = fieldsDefine.find(fd => fd.type === type);
        if (!def) return;
        return values[def.key];
    }, [fieldsDefine]);

    return {
        fieldsDefine,
        getValue,
    }
}